import React, { useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Seo from '../components/Seo';
import Layout from '../components/Layout';

interface Props {
  headerHeight: number;
  footerHeight: number;
}

const PageContainer = styled.div<Props>`
  height: calc(100vh - ${(props) => props.headerHeight + props.footerHeight}px);
  max-height: calc(100vh - ${(props) => props.headerHeight + props.footerHeight}px);
  background-color: #000;
  color: var(--cream);
  h1 {
    font-size: 3em;
  }
  p {
    margin: 1em 0;
    a {
      color: var(--orange);
      &:hover {
        color: var(--orange-focus);
      }
    }
  }
`;

// markup
const NotFoundPage: React.FC = ({ location }: any) => {
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const [footerHeight, setFooterHeight] = useState<number>(0);

  useLayoutEffect(() => {
    const header = document.querySelector('header') as HTMLElement;
    setHeaderHeight(header.offsetHeight);
    const footer = document.querySelector('footer') as HTMLElement;
    setFooterHeight(footer.offsetHeight);
  }, []);

  return (
    <Layout location={location}>
      <Seo title="Not Found" />
      <PageContainer headerHeight={headerHeight} footerHeight={footerHeight}>
        <div className="page-width">
          <h1>Page not found</h1>
          <p>
            <Link to="/">Go home</Link>.
          </p>
        </div>
      </PageContainer>
    </Layout>
  );
};

export default NotFoundPage;
